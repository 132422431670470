import { Helmet } from 'react-helmet-async';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Link, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from 'dayjs';
import { AppSiteStatusCard } from '../sections/@dashboard/app';
import { getGeneralAPIRequest, transformBessStatusData, getBessStatus } from '../utils/apiCommonMethods';

DashboardAppPage.propTypes = {
  msSessionInfo: PropTypes.object,
  allSites: PropTypes.array
};

export async function refreshBessData(axiosSession, allSites) {
  const paramsVar = new URLSearchParams();
  allSites.forEach(site => paramsVar.append("stsId", site.stsId));
  const request = {
    params: paramsVar
  };
  const siteStatusResp = await axiosSession.get('/bess/status', request);
  const transformedData = transformBessStatusData(siteStatusResp.data, allSites);
  return transformedData;
}

export default function DashboardAppPage({msSessionInfo, allSites}){
  const [siteStatuses, setSiteStatuses] = React.useState([]);
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = React.useState();

  const getLastUpdateTimeString = () => `Last Updated ${dayjs().format('hh:mm:ss')}`;

  const handleRefresh = async() => {
    const axiosSession = getGeneralAPIRequest(msSessionInfo);
    const transformedData = refreshBessData(axiosSession, allSites);
    setLastUpdatedTimestamp(getLastUpdateTimeString());
    setSiteStatuses(transformedData);
  };

  React.useEffect(() => {
    async function getBessStatusData() {
      const axiosSession = getGeneralAPIRequest(msSessionInfo);
      const transformedData = await getBessStatus(axiosSession, allSites);
      setLastUpdatedTimestamp(getLastUpdateTimeString());
      setSiteStatuses(transformedData);
    }

    let calledOnceOnPageLoad = false;
    if (!calledOnceOnPageLoad && allSites != null) {
      calledOnceOnPageLoad = true;
      getBessStatusData();
    }
  }, [msSessionInfo, allSites]);

  return (
    <>
      <Helmet>
        <title>SYSO Home</title>
      </Helmet>

      <Box sx={{ pl:5, pr:5}}>
        <Grid container spacing={3} direction="row" alignItems="flex-end">
          <Grid item xs={6} sm={6} md={6} lg={6}> 
            <Typography variant="h4" sx={{ mb: 5 }}>
              Site Summary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{pt:2}}>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Typography variant="caption">{lastUpdatedTimestamp}</Typography>
              <IconButton size="small" aria-label="expand" onClick={() => handleRefresh()}>
                <RefreshIcon />
              </IconButton>
            </Grid> 
          </Grid>
          {siteStatuses?.map((siteStatus, index) => (        
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Link underline="none" href={`/dashboard/sitestatus/${siteStatus.stsId}`}>
                  <AppSiteStatusCard 
                    title={siteStatus.site.displayName} 
                    subtitle={siteStatus.chargingStatus} 
                    subsubtitle={`${siteStatus.currentSOCText} SOC`} 
                    color={siteStatus.color} />
                </Link>
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </>
  );
};