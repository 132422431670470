import axios from "axios";
import lodash from 'lodash';
import { sysoApiConfig, siteToModeMap, scalingMap } from '../apiConfig';

export const getGeneralAPIRequest = (msSessionInfo) => {
  const apiConfig = {
      baseURL: sysoApiConfig.baseSYSOUrl,
      headers: { Authorization: `Bearer ${msSessionInfo.accessToken}` }
  };
  return axios.create(apiConfig);
}

export const sortSites = (allSites) => {
  allSites = lodash.sortBy(allSites, ['displayName']);
  return allSites;
}

export const transformBessStatusData = (siteStatusResp, allSites) => {
  let siteStatusObjList = [];
  // Raw response of GET /v1/bess/status into UI formatted schema:
  for (let j = 0; j < allSites.length; j += 1) {
    const defaultValues = {
      currentModeText : "Offline",
      color : "disable",
      currentPowerText  : "-",
      chargingStatus  : "Offline",
      currentSOCText  : "- %"
    }
    let siteStatusObj = defaultValues;
    for (let i = 0; i < siteStatusResp.length; i += 1) {
      if (siteStatusResp[i].stsId === allSites[j].stsId) {
        // Combine data from both site basic info call and bess status
        siteStatusObj = siteStatusResp[i];
        siteStatusObj.color = 'secondary'; // normal color without errors
        siteStatusObj = setModeText(siteStatusObj);
        siteStatusObj = setChargingStatus(siteStatusObj);
      }
    }
    siteStatusObj.site = allSites[j];
    siteStatusObj.stsId = allSites[j].stsId;
    siteStatusObjList.push(siteStatusObj)
  }
  siteStatusObjList = lodash.sortBy(siteStatusObjList, ['site.displayName']);
  return siteStatusObjList;
};

const setModeText = (siteStatus) => {
  siteStatus.currentModeText = '';

  if (siteStatus.mode !== undefined){
    const bmsSpecificMap = siteToModeMap.get(siteStatus.stsId);
    if (bmsSpecificMap !== undefined){
      const modeAsText = bmsSpecificMap.get(siteStatus.mode);
      if (modeAsText !== undefined){
        siteStatus.currentModeText = modeAsText;
      } else {
        siteStatus.currentModeText = siteStatus.mode.toString();
      }
      if (modeAsText === 'Shutdown'){
        siteStatus.color = 'disable';
      } else if (modeAsText === 'Fault' || modeAsText === 'Unknown'){
        siteStatus.color = 'error';
      }
    }
  }
  return siteStatus;
}

const setChargingStatus = (siteStatus) => {
  // If we lose connectivity, could not get power or soc back: 
  if (siteStatus.power === undefined){
    siteStatus.currentPowerText = '-';
    siteStatus.chargingStatus = '-';
    siteStatus.currentSOCText = '-';
    siteStatus.color = 'warning';
    return siteStatus
  }

  // Small discharge or charge (ie station load) should not show up as "charging" and "discharging"
  const thresholdForHold = 20; 
  // Already set to disable or error from Mode Check (ie, in fault mode)
  if (siteStatus.color !== 'secondary'){
    siteStatus.chargingStatus = siteStatus.currentModeText;
  } else if (siteStatus.power > thresholdForHold) {
    siteStatus.chargingStatus = 'Discharging';
  } else if (siteStatus.power < (-1 * thresholdForHold)) {
    siteStatus.chargingStatus = 'Charging';
  } else {
    siteStatus.chargingStatus = 'Hold';
    siteStatus.color = 'disable';
  }

  if (!siteStatus.soc){
    siteStatus.currentSOCText = '-';
    return siteStatus
  }
  const scalingDefault = scalingMap.get('default');
  let socScaled = scaleData(siteStatus.soc, scalingDefault.soc);
  let powerScaled = scaleData(siteStatus.power, scalingDefault.power);
  socScaled = socScaled.toFixed(1);
  powerScaled = powerScaled.toFixed(0);
  siteStatus.currentSOCText = `${socScaled.toString()}%`;
  siteStatus.currentPowerText = `${powerScaled.toString()} kW`;

  return siteStatus;
}

export const transformChartData = (chartData) => {
  const scalingDefault = scalingMap.get('default');
  const dataPreppedForCharts = {};
  
  if (chartData.tsStatus && chartData.tsStatus.power && chartData.tsStatus.soc){
    dataPreppedForCharts.power = readChart(chartData.tsStatus.power, scalingDefault.power, 2);
    dataPreppedForCharts.soc = readChart(chartData.tsStatus.soc, scalingDefault.soc, 2);
  } 
  
  if (chartData.pricing && chartData.pricing.prices){
    dataPreppedForCharts.prices = readChart(chartData.pricing.prices, 'usePrice');
  } 

  if (chartData.optimization) {
    console.log(chartData.optimization.soc)
    dataPreppedForCharts.optimSOC = readChart(chartData.optimization.soc, 'multiply100', 1);
    dataPreppedForCharts.vderOptimPower = readChart(chartData.optimization.vder.power, null, 2);
    dataPreppedForCharts.dlmOptimPower = readChart(chartData.optimization.dlm.power, null, 2);
  }

  return dataPreppedForCharts;
}

const scaleData = (oldValue, transform) => { 
  if (transform === 'divide10'){
    return (oldValue/10);
  } 
  if (transform === 'divide100'){
    return (oldValue/100);
  }  
  if (transform === 'multiply100'){
    return (oldValue*100);
  } 
  return (oldValue);
}

const readChart = (selectedChart, transform, decimalPlaces) => {
  if (decimalPlaces === null){
    decimalPlaces = 0;
  }
  // Converts [{time:x, value:y}] to separate list of labels (x-axis) and data (y-axis)
  const labels = [];
  const dataSet = [];
  for (let i = 0; i < selectedChart.length; i += 1) {
    labels.push(selectedChart[i].time);
    if (transform === 'usePrice'){
      let newPriceValue = scaleData(selectedChart[i].price, 'roundTwo');
      newPriceValue = newPriceValue.toFixed(2);
      dataSet.push(newPriceValue);
    } else {
      let newValue = scaleData(selectedChart[i].value, transform);
      newValue = lodash.round(newValue, decimalPlaces);      
      dataSet.push(newValue);
    }
  }
  return {"labels" : labels, "dataSet" : dataSet};
}

export const getBessStatus = async (axiosSession, allSites) => {
  const paramsVar = new URLSearchParams();
  allSites.forEach(site => paramsVar.append("stsId", site.stsId));
  const request = {
    params: paramsVar
  };
  const siteStatusResp = await axiosSession.get('/bess/status', request);
  const transformedData = transformBessStatusData(siteStatusResp.data, allSites);
  return transformedData;
}

export const fileDownload = (fileName, fileData) => {
  const href = URL.createObjectURL(fileData);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}