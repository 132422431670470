import { Container, Card, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableRow, IconButton} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { getGeneralAPIRequest, transformBessStatusData } from '../../utils/apiCommonMethods';
import SiteStatusCharts from './SiteStatusCharts';


const todayMidnight = dayjs().startOf('day');
const tomorrowMidnight = todayMidnight.add(1, 'day');
const thirtydaysago = todayMidnight.subtract(30, 'day');

SiteStatus.propTypes = {
  msSessionInfo: PropTypes.object,
  site: PropTypes.object
};

export async function getBessStatus(axiosSession, site) {
  const bessStatusrequest = {
    params: { "stsId": site.stsId }
  };
  const statusResponse = await axiosSession.get('/bess/status', bessStatusrequest);
  const transformedData = transformBessStatusData(statusResponse.data, [site]);
  const batteryPowerText = `${transformedData[0].chargingStatus} (${transformedData[0].currentPowerText})`;
  const siteTableData = [
    { "label": "Current Mode", "value": transformedData[0].currentModeText },
    { "label": "Current BESS Power", "value": batteryPowerText },
    { "label": "Current SOC", "value": transformedData[0].currentSOCText }
  ];
  return siteTableData;
}

export async function getBessTimeseries(axiosSession, site, startDate, endDate){
  const tsRequest = {
    params: { "stsId": site.stsId, "bin": "PT15M" }
  };
  if (startDate !== null && endDate !== null){
    tsRequest.params.start = startDate.format();
    tsRequest.params.end = endDate.format();
  }
  const tsResponse = await axiosSession.get('/bess/timeseries', tsRequest);
  return tsResponse.data;
}

export async function getPricingData(axiosSession, site, startDate, endDate){
  const pricingRequest = {
    params: { "start": startDate.format(), "end": endDate.format()}
  };
  const endpoint = `/prices/${site.iso}/zones/${site.isoZone}/timeseries`;
  const pricingResponse = await axiosSession.get(endpoint, pricingRequest);
  return pricingResponse.data;
}

export async function getOptimizationData(axiosSession, site, startDate, endDate, horizon, optimType){
  let optimResponse;  
  
  const optimizationRequest = {
      params: {
        "stsId":site.stsId,
        "points":["power","soc"],
        "bin":"PT1H",
        "start": startDate.format(),
        "end": endDate.format(),
      }
  };
  
  if (horizon){
    optimizationRequest.params.horizon = horizon;
  }

  try {
    optimResponse =  await axiosSession.get(`/optimization/${optimType}/timeseries`, optimizationRequest);
  } catch (error){
    console.log(error);
    return null;
  }
  
  return optimResponse.data;
}

export async function refreshTimeseriesData(axiosSession, site, newStartDate){
  // End is 11:59PM so we don't show the first row of the following day: 
  const newEndDate = newStartDate.add(1, 'day').subtract(1, 'minute'); 
  const allChartData = {};
  if (newStartDate.isAfter(todayMidnight, 'day')){
    // Date is in future, clear Power and SOC charts:
    allChartData.tsStatus = {"power" : [], "soc" : []};
  } else {
    allChartData.tsStatus = await getBessTimeseries(axiosSession, site, newStartDate, newEndDate);
  }
  if (site.iso === 'nyiso'){
    let horizonVar = null;
    if (newEndDate < todayMidnight){
      horizonVar = "PT1H";
    }
    allChartData.pricing = await getPricingData(axiosSession, site, newStartDate, newEndDate);
    allChartData.optimization = {};
    allChartData.optimization.vder = await getOptimizationData(axiosSession, site, newStartDate, newEndDate, horizonVar, 'vder');
    allChartData.optimization.dlm = await getOptimizationData(axiosSession, site, newStartDate, newEndDate, horizonVar, 'dlm');
    allChartData.optimization.soc = sumOptimSoc([allChartData.optimization.vder.soc, allChartData.optimization.dlm.soc]);
  }
  return allChartData;
}

export function sumOptimSoc(optimSocs){

  // Remove any undefined values
  optimSocs = _.compact(optimSocs)

  var totalSoc = _(_.concat(...optimSocs))
  .groupBy('time')
  .map((objs, key) => ({'time': key, 'value': _.sumBy(objs, 'value') }))
  .orderBy(['time'], ['asc'])
  .value();

  return totalSoc;
}

export default function SiteStatus({ msSessionInfo, site }) {
  const [showStatusTable, setShowStatusTable] = useState(true);
  const [selectedDate, setDate] = useState(todayMidnight);
  const [siteTableData, setSiteTableData] = useState([]);
  const [allChartData, setAllChartData] = useState();
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState();

  const getLastUpdateTimeString = () => `Last Updated ${dayjs().format('hh:mm:ss')}`;

  const handleRefresh = async () => {
    refreshStatusTable();
    const allChartData = await refreshTimeseriesData(getGeneralAPIRequest(msSessionInfo), site, selectedDate);
    setAllChartData(allChartData);
    setLastUpdatedTimestamp(getLastUpdateTimeString);
  };

  const handleDateChange = async (newValue) => {
    const newDate = newValue;
    setDate(newDate);
    if (newDate.isSame(todayMidnight, 'day')){
      refreshStatusTable();
      setShowStatusTable(true);
    } else {
      setShowStatusTable(false);
    }
    const allChartData = await refreshTimeseriesData(getGeneralAPIRequest(msSessionInfo), site, newDate);
    setAllChartData(allChartData);
    setLastUpdatedTimestamp(getLastUpdateTimeString);
  };

  const refreshStatusTable = async () => {
    const bessStatus = await getBessStatus(getGeneralAPIRequest(msSessionInfo),site);
    setSiteTableData(bessStatus);
  }

  useEffect(() => {
    if (site != null) {
      const axiosSession = getGeneralAPIRequest(msSessionInfo);

      const fetchAllDataOnPageLoad = async (axiosSession) => {
        console.log('IN fetchAllDataOnPageLoad');
        const bessStatus = await getBessStatus(axiosSession, site);
        setSiteTableData(bessStatus);

        const allChartData = {};
        allChartData.tsStatus = await getBessTimeseries(axiosSession, site, todayMidnight, tomorrowMidnight);

        if (site.iso === 'nyiso') {
          allChartData.pricing = await getPricingData(axiosSession, site, todayMidnight, tomorrowMidnight);
          allChartData.optimization = {};
          allChartData.optimization.vder = await getOptimizationData(axiosSession, site, todayMidnight, tomorrowMidnight, null, 'vder');
          allChartData.optimization.dlm = await getOptimizationData(axiosSession, site, todayMidnight, tomorrowMidnight, null, 'dlm');
          allChartData.optimization.soc = sumOptimSoc([allChartData.optimization.vder.soc, allChartData.optimization.dlm.soc]);
        }
        setAllChartData(allChartData);
      }      
      setLastUpdatedTimestamp(getLastUpdateTimeString);
      fetchAllDataOnPageLoad(axiosSession);
    }
  }, [msSessionInfo, site]);

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
          <Grid item xs={12} md={6} lg={6} order={{ xs: 2, sm: 2, md:1, lg:1}}>
            {showStatusTable ? (
              <Card>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      {siteTableData?.map((row) => (
                        <TableRow
                          key={row.label}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.label}
                          </TableCell>
                          <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <IconButton sx={{ pb: 2, float: 'right' }} size="small" aria-label="expand" onClick={() => handleRefresh()}>
              <RefreshIcon />
            </IconButton>
            <Typography sx={{ pt: 1, float: 'right' }} variant="caption">{lastUpdatedTimestamp}</Typography>
            <DatePicker
              label="Date"
              sx={{ bgcolor: 'background.paper', width: 1 }}
              minDate={thirtydaysago}
              maxDate={tomorrowMidnight}
              value={selectedDate}
              onChange={handleDateChange}
              views={['day']}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} order={{ xs: 3, sm: 3, md:3, lg:3}}>
            { /* Charts : */}
            <SiteStatusCharts chartData={allChartData} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
